<template>
    <div
        class="avatar-container"
        :style="{ width: size + 'px', height: size + 'px' }"
    >
        <img
            :src="tracker.asset_details.asset_type_marker"
            :style="{
                borderColor: tracker.asset_details.color,
                boxShadow: boxShadow,
                border: border,
            }"
        />
        <div v-if="tracker.asset_details.alert" class="avatar_warning-icon">
            <warning-icon :height="warningIconSize" :width="warningIconSize" />
        </div>
    </div>
</template>

<script>
import WarningIcon from '@/components/icons/WarningIcon'

export default {
    name: 'AssetAvatar',
    components: { WarningIcon },
    props: {
        tracker: {
            type: Object,
            required: true,
        },
        size: {
            type: Number,
            default: 64,
        },
    },
    computed: {
        warningIconSize() {
            return Math.round(this.size * 0.25)
        },
        boxShadow() {
            let offset = Math.round(this.size * 0.08)
            return '0px ' + offset + 'px 5px 0px rgba(0, 0, 0, 0.4)'
        },
        border() {
            let borderSize = Math.round(this.size * 0.045)
            return borderSize + 'px solid ' + this.tracker.asset_details.color
        },
    },
}
</script>

<style lang="scss" scoped>
.avatar-container {
    position: relative;
    flex-shrink: 0;

    img {
        padding: 12.5%;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 1);
    }

    .avatar_warning-icon {
        position: absolute;
        top: -12%;
        right: -12%;
        padding: 10%;
        border-radius: 50%;
        background-color: #fff;
    }
}
</style>
