<template>
    <Multiselect
        v-model="valueInternal"
        :options="options"
        track-by="id"
        label="name"
        :placeholder="shouldShowPlaceholder ? '' : placeholder"
        :multiple="multiple"
        :allow-empty="multiple"
        :max="options.length || null"
        :close-on-select="!multiple"
        :hide-selected="multiple"
        :show-labels="false"
        @input="onInput"
    >
        <template #singleLabel="{ option }">
            <div class="option">
                <img :src="option.marker.url" />

                <span>{{ option.name }}</span>
            </div>
        </template>

        <template #option="{ option }">
            <div class="option">
                <img :src="option.marker.url" />

                <span>{{ option.name }}</span>
            </div>
        </template>

        <template #noOptions>
            {{ $t('noOptions') }}
        </template>

        <template #noResult>
            {{ $t('shared.noSearchResult') }}
        </template>

        <template #maxElements>
            {{ $t('noOptions') }}
        </template>
    </Multiselect>
</template>

<script>
import { mapState } from 'vuex'
import Multiselect from 'vue-multiselect'

export default {
    name: 'AssetTypeSelect',
    components: {
        Multiselect,
    },
    props: {
        value: {
            type: [Number, Array],
            required: true,
        },
        placeholder: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            valueInternal: null,
        }
    },
    computed: {
        ...mapState('tracker', ['assetTypes']),
        options() {
            return this.assetTypes
                .map(type => {
                    return {
                        ...type,
                        name: this.$root.$i18n.te(
                            `shared.types.${type.identifier}`
                        )
                            ? this.$t(`shared.types.${type.identifier}`)
                            : type.name,
                    }
                })
                .sort((a, b) => a.name.localeCompare(b.name))
        },
        shouldShowPlaceholder() {
            return this.multiple
                ? this.valueInternal && this.valueInternal.length
                : !!this.valueInternal
        },
    },
    watch: {
        value() {
            if (!this.multiple) {
                if (!this.value) {
                    this.valueInternal = this.valuePreserved
                    return
                }
            }

            this.valueInternal = this.multiple
                ? this.options.filter(option => this.value.includes(option.id))
                : this.options.find(option => this.value === option.id)

            this.valuePreserved = this.valueInternal
        },
    },
    mounted() {
        if (!this.value) {
            return
        }

        this.valueInternal = this.multiple
            ? this.options.filter(option => this.value.includes(option.id))
            : this.options.find(option => this.value === option.id)
    },
    methods: {
        onInput(value) {
            this.$emit(
                'input',
                this.multiple ? value.map(item => item.id) : value.id
            )
        },
    },
}
</script>

<i18n>
{
    "en": {
        "noOptions": "No options available"
    },
    "de": {
        "noOptions": "Keine Optionen verfügbar"
    },
    "it": {
        "noOptions": "Nessuna opzione disponibile"
    }
}
</i18n>

<style lang="scss" scoped>
.option {
    display: flex;
    align-items: center;

    img {
        width: 32px;
        padding-right: 12px;
    }

    span {
        margin-top: 2px;
    }
}
</style>
