<template>
    <Multiselect
        v-model="valueInternal"
        :options="options"
        track-by="id"
        label="name"
        :placeholder="shouldShowPlaceholder ? '' : placeholder"
        :multiple="multiple"
        :allow-empty="multiple"
        :max="options.length || null"
        :close-on-select="!multiple"
        :hide-selected="multiple"
        :show-labels="false"
        @input="onInput"
    >
        <template #option="{ option: { name, description } }">
            <div class="option">
                <span>{{ name }}</span>

                <span class="description">{{ description }}</span>
            </div>
        </template>

        <template #noOptions>
            {{ $t('noOptions') }}
        </template>

        <template #noResult>
            {{ $t('noResult') }}
        </template>

        <template #maxElements>
            {{ $t('noOptions') }}
        </template>
    </Multiselect>
</template>

<script>
import { mapGetters } from 'vuex'
import Multiselect from 'vue-multiselect'

export default {
    name: 'LocationSelect',
    components: {
        Multiselect,
    },
    props: {
        value: {
            type: [Number, Array, String],
            default: null,
        },
        placeholder: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        canBeLeftUnchanged: {
            type: Boolean,
            default: false,
        },
        removable: {
            type: Boolean,
            default: false,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            valueInternal: null,
        }
    },
    computed: {
        ...mapGetters('locations', ['getLocationsSortedByName']),
        options() {
            let opt = []
            if (this.removable) {
                opt.push({
                    id: null,
                    name: this.$t('removeLocation'),
                    description: '',
                })
            }
            if (this.canBeLeftUnchanged) {
                opt.push({
                    id: 'NOT_SET',
                    name: this.$t('doNotModifyLocation'),
                    description: '',
                })
            }
            return [...opt, ...this.getLocationsSortedByName]
        },
        shouldShowPlaceholder() {
            return this.multiple
                ? this.valueInternal && this.valueInternal.length
                : !!this.valueInternal
        },
    },
    watch: {
        value() {
            this.valueInternal = this.multiple
                ? this.options.filter(option => this.value.includes(option.id))
                : this.options.find(option => this.value === option.id)
        },
    },
    mounted() {
        this.valueInternal = this.multiple
            ? this.options.filter(option => this.value.includes(option.id))
            : this.options.find(option => this.value === option.id)
    },
    methods: {
        onInput(value) {
            this.$emit(
                'input',
                this.multiple ? value.map(item => item.id) : value.id
            )
        },
    },
}
</script>

<i18n>
{
    "en": {
        "doNotModifyLocation": "Do not modify location",
        "noOptions": "No locations available",
        "noResult": "No Location found",
        "removeLocation": "No location"
    },
    "de": {
        "doNotModifyLocation": "Standort nicht ändern",
        "noOptions": "Keine Standorte verfügbar",
        "noResult": "Keine Standort gefunden",
        "removeLocation": "Kein Standort"
    },
    "it": {
        "doNotModifyLocation": "Non modificare la locazione",
        "noOptions": "Nessuna locazione disponibile",
        "noResult": "Nessuna località trovata",
        "removeLocation": "Nessuna locazione"
    }
}
</i18n>

<style lang="scss" scoped>
.option {
    width: 100%;
    white-space: normal;

    .description {
        padding-left: 4px;
        font-weight: 300;
        color: $color-gray-dark;
    }
}
</style>
